import React from 'react'
import { SearchBox, SearchBoxProps } from 'msp-components'

const JasminSearchBox = ({
  className,
  classNameResults = '',
  formRef,
  inputIcon,
  inputPlaceholder,
  inputClassName = '',
  position = 'absolute',
  onResultClick,
  onSearching,
  onSearch,
  onClose,
  showDeleteButton,
  searchValue,
  iconColor,
  isDarkMode,
  isSuggestionWhiteMode
}: SearchBoxProps) => {
  return (
    <SearchBox
      className={className}
      classNameResults={classNameResults}
      formRef={formRef}
      inputIcon={inputIcon}
      inputPlaceholder={inputPlaceholder}
      inputClassName={inputClassName}
      position={position}
      onResultClick={onResultClick}
      onSearching={onSearching}
      onSearch={onSearch}
      onClose={onClose}
      showDeleteButton={showDeleteButton}
      searchValue={searchValue}
      iconColor={iconColor}
      isDarkMode={isDarkMode}
      uiConfig={{
        searchIconName: 'Search',
        searchFieldLetterSpacing: 'tracking-0',
        resultTitleLetterSpacing: `tracking-0 text-14 ${
          isDarkMode ? 'border-white' : 'borger-black'
        }`,
        resultLetterSpacing: 'font-Lora font-bold text-18 tracking-0',
        suggestionLineStyle:
          isDarkMode && !isSuggestionWhiteMode
            ? 'hover:bg-black'
            : 'hover:bg-lightGray',
        sugggestionTypeStyle: isDarkMode ? 'border-mediumGray' : 'border-black'
      }}
    />
  )
}

export default JasminSearchBox
