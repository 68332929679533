import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getAssetUrl } from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import { Availability, Button, Spinner, Headline } from 'msp-components'
import {
  findImageGroupBy,
  getImageFromImageGroup,
  ProductImage,
  useConfig
} from 'msp-integrations'
import useProduct from '@/hooks/use-product'
import { BonusProductLineProps } from './BonusProductLine.types'

const BonusProductLine = ({
  productId,
  quantity = 1,
  bonusDiscountLineItemId,
  basket,
  selectedBonusProducts = new Map<string, any>(),
  maxBonusItems,
  closeModal = () => {},
  showAlert = () => {}
}: BonusProductLineProps) => {
  const { formatMessage } = useIntl()
  const {
    theme: { fontVariant }
  } = useConfig()
  const product = useProduct(productId)
  const { ats, orderable, preorderable, stockLevel } =
    product?.c_inventory || {}

  const [selectedQuantity, setSelectedQuantity] = useState(quantity)
  const [productImage, setProductImage] = useState({} as ProductImage)
  const [itemAdded, setItemAdded] = useState(false)

  useEffect(() => {
    if (product) {
      product.name = product.productName || product.name
    }

    if (product?.imageGroups) {
      const largeImageGroup = findImageGroupBy(product.imageGroups, {
        viewType: 'large',
        selectedVariationAttributes: {}
      })

      setProductImage({
        link: getImageFromImageGroup(largeImageGroup, 0, {
          scaleWidth: 150
        }),
        alt: largeImageGroup?.images[0]?.alt
      })
    }
  }, [product?.imageGroups])

  useEffect(() => {
    let bonusQuantity = 0
    selectedBonusProducts.forEach((bonusProductItem) => {
      bonusQuantity += bonusProductItem.quantity
    })

    if (itemAdded && bonusQuantity >= maxBonusItems) {
      closeModal()
      setItemAdded(false)
    }
  }, [itemAdded, selectedBonusProducts])

  const handleImageNotFound = (el: HTMLImageElement) => {
    const fallBackImage = getAssetUrl('static/img/fallback-product-image.png')
    if (el.src !== fallBackImage) {
      el.src = fallBackImage
    }
  }

  const onAddToCartHandler = () => {
    const addTitleError = formatMessage({
      defaultMessage:
        'Please remove your selected product by clicking on the bin before adding a new product.',
      id: 'msp.choice_of_bonus_products_modal.alert.add.error'
    })
    const addTitleSuccess = formatMessage({
      defaultMessage: 'Bonus Product was successfully added to your cart.',
      id: 'msp.choice_of_bonus_products_modal.alert.add.success'
    })

    if (selectedBonusProducts.has(productId)) {
      const { itemId } = selectedBonusProducts.get(productId)
      basket
        .updateItemInBasket(
          { productId, quantity: selectedQuantity, price: product.price },
          itemId
        )
        .then((response) => {
          if (response?.isError) {
            showAlert({
              type: 'error',
              title: addTitleError
            })
          } else {
            setItemAdded(true)
            showAlert({
              type: 'success',
              title: addTitleSuccess
            })
          }
        })
    } else {
      basket
        .addItemToBasket([
          {
            productId,
            bonusDiscountLineItemId,
            quantity: selectedQuantity,
            price: product.price
          }
        ])
        .then((response) => {
          if (response?.isError) {
            showAlert({
              type: 'error',
              title: addTitleError
            })
          } else {
            setItemAdded(true)
            showAlert({
              type: 'success',
              title: addTitleSuccess
            })
          }
        })
    }
  }

  const onRemoveFromCartHandler = () => {
    if (selectedBonusProducts.has(productId)) {
      const { itemId } = selectedBonusProducts.get(productId)
      basket.removeItemFromBasket(itemId).then((response) => {
        if (response?.isError) {
          showAlert({
            type: 'error',
            title: formatMessage({
              defaultMessage:
                'A technical error is occured. Please retry later or contact our support.',
              id: 'msp.choice_of_bonus_products_modal.alert.remove.error'
            })
          })
        } else {
          showAlert({
            type: 'success',
            title: formatMessage({
              defaultMessage:
                'Bonus Product was successfully removed from your cart.',
              id: 'msp.choice_of_bonus_products_modal.alert.remove.success'
            })
          })
        }
      })
    }
  }

  return (
    <>
      {!product.showLoading ? (
        <div className='flex w-full flex-col border-t-[1px] md:flex-row'>
          <div className='flex flex-grow'>
            <div className='flex-none pr-2'>
              <LazyLoadImage
                src={productImage.link}
                alt={productImage.alt ?? product.name}
                width='150'
                height='150'
                onError={(ev) =>
                  handleImageNotFound(ev.target as HTMLImageElement)
                }
              />
            </div>
            <div className='flex h-[150px] grow flex-col content-start justify-between p-4 md:h-full md:p-8'>
              <Headline tag='h6' className={`${fontVariant.regular} uppercase`}>
                {product.name}
              </Headline>
              <Availability
                className='self-start'
                ats={ats}
                orderable={orderable}
                preorderable={preorderable}
                stockLevel={stockLevel}
              />
            </div>
          </div>
          <div className='ml-auto flex flex-none p-2 md:m-auto md:ml-0 md:p-1'>
            <div className='pr-2'>
              <Button
                type='primary'
                isDisabled={!orderable}
                onClick={() => onAddToCartHandler()}
              >
                {formatMessage({
                  defaultMessage: 'Add to cart',
                  id: 'msp.bonus_product.button.add_to_cart'
                })}
              </Button>
            </div>
            <div className='flex'>
              <Button
                type='tertiary'
                iconName='Delete'
                isDisabled={!orderable || !selectedBonusProducts.has(productId)}
                onClick={() => onRemoveFromCartHandler()}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='flex min-h-[140px] min-w-[324px] items-center justify-center pt-2'>
          <Spinner size='lg' />
        </div>
      )}
    </>
  )
}

export default BonusProductLine
