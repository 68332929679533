import React, { useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router'
import {
  Region,
  transformRegionToMap,
  useCountryData,
  useCustomer,
  useBasket,
  useNavigation,
  useMultiSite,
  useCookie
} from 'msp-integrations'
import {
  ShowOnDesktop,
  LanguageModal,
  EventsEmitter,
  Events,
  CustomLink
} from 'msp-components'
import { IconButton } from '@/components/molecules/IconButton'
import { UserHeaderIcon } from '@/components/molecules/UserHeaderIcon'
import JasminMiniCart from '@/components/organisms/JasminMiniCart/JasminMiniCart'
import JasminDesktopSearch from '@/components/organisms/JasminDesktopSearch/JasminDesktopSearch'
import ChoiceOfBonusProductsModal from '@/components/organisms/ChoiceOfBonusProductsModal/ChoiceOfBonusProductsModal'
import FixedHeaderWrapper from './partials/FixedHeaderWrapper'
import { HeaderProps } from './Header.types'
import { MIN_AMOUNT_FREE_SHIPPING, MIN_BOXES_FREE_DELIVERY } from '@/constants'

const defaults = {
  mobileHeaderHeight: 'h-[66px]',
  desktopHeaderHeight: 'md:h-[65px]',
  iconsHeight: '20px',
  iconsSpacing: 'space-x-[18px] md:space-x-6',
  backgroundColor: {
    transparent: 'bg-transparent',
    solid: 'bg-black'
  },
  contentColor: {
    light: 'text-white fill-white',
    dark: 'text-machine2-100 fill-machine2-100'
  }
}

const Header = ({
  regions,
  isTransparent,
  lightContent,
  onMenuClick,
  onUserClick
}: HeaderProps) => {
  const { eventTracking } = useCookie()
  const i18n = useCountryData()
  const navigate = useNavigation()
  const customer = useCustomer()
  const [showMiniCart, setShowMiniCart] = useState(false)
  const basket = useBasket()
  const { buildUrl } = useMultiSite()
  const { path } = useRouteMatch()
  const inCartPage = path && path.includes('/cart')
  const [showLangModal, setShowLangModal] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const addToCartEmitter = useRef(EventsEmitter)
  const isMounted = useRef(false)
  const { c_minAmountFreeShipping, c_minBoxesFreeDelivery } = basket
  const minAmountFreeShipping =
    c_minAmountFreeShipping || MIN_AMOUNT_FREE_SHIPPING
  const minBoxesFreeDelivery = c_minBoxesFreeDelivery || MIN_BOXES_FREE_DELIVERY

  useEffect(() => {
    isMounted.current = true

    const subscription = addToCartEmitter.current?.subscribe(
      Events.ADD_TO_CART_SUCCESS,
      () => {
        if (!showMiniCart && isMounted.current) {
          setShowMiniCart(true)
        }
      }
    )

    return () => {
      isMounted.current = false
      subscription?.unsubscribe()
    }
  }, [showMiniCart])

  const headerColors = `${
    isTransparent
      ? defaults.backgroundColor.transparent
      : defaults.backgroundColor.solid
  } ${
    isTransparent && lightContent
      ? defaults.contentColor.light
      : defaults.contentColor.dark
  }`

  const handleOpenLanguageModal = () => {
    eventTracking('header_tracking', { event_action: 'language' })
    setShowLangModal(true)
  }

  const wrapperStyles = `${defaults.mobileHeaderHeight} ${defaults.desktopHeaderHeight} ${headerColors}`

  return (
    <>
      <FixedHeaderWrapper className={wrapperStyles}>
        <div
          id='main-header'
          className={'flex h-full items-center justify-center'}
        >
          <div
            className={
              'flex flex-auto justify-start stroke-white pr-[18px] md:flex-1 md:pr-6'
            }
          >
            <IconButton
              iconName='Menu'
              width={defaults.iconsHeight}
              height='20px'
              onClick={onMenuClick}
              event_name='header_tracking'
              event_action='burgermenu'
            />
          </div>

          <CustomLink
            className='w-auto flex-none cursor-pointer justify-self-center fill-white'
            href={buildUrl('/')}
            title='Avoury | The Tea'
          >
            <IconButton
              iconName='JasminLogo'
              height='40px'
              width='150px'
              event_name='header_tracking'
              event_action='logo'
            />
          </CustomLink>

          <div
            className={`flex flex-auto items-center justify-end stroke-white md:flex-1 ${defaults.iconsSpacing}`}
          >
            <ShowOnDesktop>
              <IconButton
                iconName='Search'
                onClick={() => setShowSearch(true)}
                height={defaults.iconsHeight}
                width={defaults.iconsHeight}
                event_name='header_tracking'
                event_action='search'
              />
            </ShowOnDesktop>
            <UserHeaderIcon
              className='fill-white stroke-white text-white'
              onClick={onUserClick}
              iconSize={defaults.iconsHeight}
              isAuthenticated={customer && customer.isRegistered}
              customerName={
                customer && customer.isRegistered
                  ? `${customer.firstName} ${customer.lastName}`
                  : ''
              }
            />
            <IconButton
              iconName={'Wishlist'}
              onClick={() => navigate('/account/wishlist')}
              height={'18px'}
              width={'18px'}
              event_name='header_tracking'
              event_action='wishlist'
            />
            <IconButton
              iconName={'Basket'}
              onClick={() => setShowMiniCart(true)}
              height={'22px'}
              width={'22px'}
              event_name='header_tracking'
              event_action='cart'
            />
            <ShowOnDesktop>
              <button
                className={`flex w-fit items-center rounded-[9px] border border-white bg-transparent px-[9px] py-[3px] outline-none`}
                onClick={() => handleOpenLanguageModal()}
              >
                <p className='text-10 tracking-0 font-light uppercase leading-normal text-white'>
                  {i18n.locale?.split('_')[0]}
                </p>
              </button>
            </ShowOnDesktop>
            <LanguageModal
              countries={i18n.countries}
              locale={i18n.locale}
              show={showLangModal}
              setShow={setShowLangModal}
            />
          </div>
        </div>
      </FixedHeaderWrapper>
      <ShowOnDesktop className='relative'>
        <JasminDesktopSearch
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          isDarkMode
        />
      </ShowOnDesktop>
      <JasminMiniCart
        showMiniCart={inCartPage ? false : showMiniCart}
        setShowMiniCart={setShowMiniCart}
        basket={basket}
        minAmountFreeShipping={minAmountFreeShipping}
        minBoxesFreeDelivery={minBoxesFreeDelivery}
      />
      <ChoiceOfBonusProductsModal />
    </>
  )
}

export default Header
