import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { getAssetUrl } from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import {
  findImageGroupBy,
  getImageFromImageGroup,
  productUrlBuilder,
  useWindowSize,
  useMultiSite,
  useNavigation
} from 'msp-integrations'
import {
  MiniCart,
  EventsEmitter,
  Events,
  Button,
  AlertBox,
  MiniCartWrapper,
  BrandMiniCartProps
} from 'msp-components'

const JasminMiniCart = ({
  basket,
  goToCartButton,
  inCartPage,
  minAmountFreeShipping,
  minBoxesFreeDelivery,
  setShowMiniCart,
  showMiniCart
}: BrandMiniCartProps) => {
  const { formatMessage } = useIntl()
  const { width, breakpoints } = useWindowSize()
  const navigate = useNavigation()
  const { buildUrl } = useMultiSite()
  const fallBackImage = getAssetUrl('static/img/fallback-product-image.png')
  const alertBoxRef = useRef<AlertBox>()
  const [alertBoxMessage, setAlertBoxMessage] = useState({
    type: 'error',
    message: ''
  })
  const { productsQuantity } = basket

  const onShowAlert = (alert: { type: string; message: string }) => {
    setAlertBoxMessage(alert)
    alertBoxRef?.current?.showAlert()
  }

  return (
    <>
      <AlertBox
        ref={alertBoxRef}
        type={alertBoxMessage.type}
        title={alertBoxMessage.message}
        autoCloseTime={2000}
        position='bottom'
        showBackdrop={false}
      />
      <MiniCartWrapper
        hasFlyOut={!inCartPage}
        data={{ showMiniCart, setShowMiniCart, productsQuantity }}
      >
        <MiniCart
          basket={basket}
          inCartPage={inCartPage}
          fallBackImage={fallBackImage}
          inline={!setShowMiniCart && width && width > breakpoints['2xl']}
          onClose={() => setShowMiniCart && setShowMiniCart(false)}
          handleBonusDiscountLineItem={(bonusDiscountLineItem: any) => {
            EventsEmitter.dispatch(
              Events.OPEN_CHOICE_OF_BONUS_PRODUCTS_MODAL,
              bonusDiscountLineItem
            )
          }}
          productUrlBuilder={productUrlBuilder}
          buildUrl={buildUrl}
          findImageGroupBy={findImageGroupBy}
          getImageFromImageGroup={getImageFromImageGroup}
          productsQuantity={productsQuantity}
          minAmountFreeShipping={minAmountFreeShipping}
          minBoxesFreeDelivery={minBoxesFreeDelivery}
          GoToCartButton={
            goToCartButton || (
              <Button
                type='primary'
                className='mb-3 w-full'
                onClick={() => navigate('/cart')}
              >
                {formatMessage({
                  id: 'msp.minicart.cta.go.cart',
                  defaultMessage: 'To cart'
                })}
              </Button>
            )
          }
          GoToShopButton={
            <Button
              type='primary'
              variant='outline'
              className='mb-3 w-full'
              onClick={() => navigate('/shop')}
            >
              {formatMessage({
                id: 'msp.minicart.cta.go.shop',
                defaultMessage: 'Continue shopping'
              })}
            </Button>
          }
          onWishlistChange={onShowAlert}
        />
      </MiniCartWrapper>
    </>
  )
}

export default JasminMiniCart
