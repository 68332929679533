import React from 'react'
import { useIntl } from 'react-intl'
import { SideFlyoutContainer, LoginForm } from 'msp-components'
import { useCustomer } from 'msp-integrations'
import { UserAccountFlyoutSummary } from '@/components/molecules/UserAccountFlyoutSummary'
import { LoginFlyoutProps } from './LoginFlyout.types'

const LoginFlyout = ({
  className,
  isOpen,
  onOpen,
  onClose
}: LoginFlyoutProps) => {
  const { formatMessage } = useIntl()
  const customer = useCustomer()

  return (
    <SideFlyoutContainer
      className={className}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      position='right'
      titleContent={
        customer.isRegistered
          ? `${formatMessage({
              defaultMessage: 'Hello',
              id: 'msp.account_detail.title_compliment'
            })} ${customer.firstName}`
          : formatMessage({
              defaultMessage: 'Login',
              id: 'msp.login_form.title'
            })
      }
    >
      <div className='px-10'>
        {customer.isRegistered ? (
          <UserAccountFlyoutSummary onLogoutSuccessful={onClose} />
        ) : (
          <LoginForm inFlyout={true} onLoginSuccessful={onClose} />
        )}
      </div>
    </SideFlyoutContainer>
  )
}

export default LoginFlyout
