import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { transformRegionToMap } from 'msp-components'
import { Header as HeaderComponent } from '@/components/organisms/Header'
import { HeaderMenuFlyout } from '@/components/organisms/HeaderMenuFlyout'
import { LoginFlyout } from '@/components/organisms/LoginFlyout'

type HeaderProps = {
  data: any
  regions: Array<any>
}

const Header = ({ regions }: HeaderProps) => {
  const { pathname } = useLocation()

  const [isMainMenuOpen, setIsMainMenuOpen] = useState<boolean>(false)
  const [isLoginFlyoutOpen, setIsLoginFlyoutOpen] = useState<boolean>(false)

  useEffect(() => {
    closeMainMenuDrawer()
  }, [pathname])

  const openMainMenuDrawer = () => {
    setIsMainMenuOpen(true)
  }
  const closeMainMenuDrawer = () => {
    setIsMainMenuOpen(false)
  }

  const openLoginFlyout = () => {
    setIsLoginFlyoutOpen(true)
  }

  const closeLoginFlyout = () => {
    setIsLoginFlyoutOpen(false)
  }

  const areas = transformRegionToMap(regions)

  return (
    <>
      <HeaderComponent
        onMenuClick={openMainMenuDrawer}
        onUserClick={openLoginFlyout}
        regions={regions}
      />
      <HeaderMenuFlyout
        isOpen={isMainMenuOpen}
        onOpen={openMainMenuDrawer}
        onClose={closeMainMenuDrawer}
        areas={areas}
      />
      <LoginFlyout
        isOpen={isLoginFlyoutOpen}
        onOpen={openLoginFlyout}
        onClose={closeLoginFlyout}
      />
    </>
  )
}

export default Header
