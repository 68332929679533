import React, { ReactNode, useEffect, useState } from 'react'

const FixedHeaderWrapper = ({
  children,
  className
}: {
  children: ReactNode
  className?: string
}) => {
  const [yPosition, setYPosition] = useState(0)
  const [showHeader, setShowHeader] = useState(true)

  const setBannerElemPosition = (pos?: number) => {
    const headerElem = document.getElementById('main-header')
    const bannerElementsIds = [
      'top-info-banner',
      'top-news-banner',
      'top-newsletter-banner'
    ]
    const transformElement = (elementId: string) => {
      const element = document.getElementById(elementId)
      if (element) {
        element.style.transform = `translateY(${
          pos !== undefined ? pos : headerElem?.clientHeight || 0
        }px)`
      }
    }

    bannerElementsIds.forEach(transformElement)
  }

  useEffect(() => {
    const handleScroll = () => {
      //forcing position to minimum 0 to avoid Safari bounce effect
      const position = Math.max(window.scrollY, 0)
      if (yPosition >= position) {
        setShowHeader(true)
        setBannerElemPosition()
      } else if (yPosition < position) {
        setShowHeader(false)
        setBannerElemPosition(0)
      }

      if (position === 0) {
        setBannerElemPosition(0)
      }

      setYPosition(position)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [yPosition])

  return (
    <div
      className={`fixed top-0 w-screen px-6 md:px-10 ${
        showHeader ? 'translate-y-0' : '-translate-y-full'
      } color opacity z-[999] transform transition duration-500 ${className}`}
    >
      {children}
    </div>
  )
}

export default FixedHeaderWrapper
