import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Tab } from '@headlessui/react'
import { useCustomer } from 'msp-integrations'
import { Icons, LoginForm, NewsletterForm } from 'msp-components'
import { UserAccountFlyoutSummary } from '@/components/molecules/UserAccountFlyoutSummary'
import { HeaderMenuBottomProps } from './HeaderMenuBottom.types'

const HeaderMenuBottom = ({ className, onSuccess }: HeaderMenuBottomProps) => {
  const { formatMessage } = useIntl()
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)
  const customer = useCustomer()

  const showNewsletterMessage = () => {
    setShowConfirmationMessage(true)
  }

  const hideNewsletterMessage = () => {
    setShowConfirmationMessage(false)
  }

  const tabs = [
    {
      iconName: 'UserAccount',
      panelTitle: customer.isRegistered
        ? `${formatMessage({
            defaultMessage: 'Hello',
            id: 'msp.login_form.title.compliment'
          })} ${customer.firstName}`
        : formatMessage({
            defaultMessage: 'Login',
            id: 'msp.login_form.title.login'
          }),
      panelContent: customer.isRegistered ? (
        <UserAccountFlyoutSummary onLogoutSuccessful={onSuccess} isDarkMode />
      ) : (
        <LoginForm inFlyout isDarkMode />
      )
    },
    {
      iconName: 'Mail',
      panelTitle: formatMessage({
        defaultMessage: 'Newsletter',
        id: 'msp.newsletter.title'
      }),
      panelContent: showConfirmationMessage ? (
        <div className='font-Roboto text-16 tracking-0 text-white'>
          {formatMessage({
            defaultMessage:
              'Thank you for your registration. You will soon receive an email to confirm your email address.',
            id: 'msp.newsletter.success_message'
          })}
        </div>
      ) : (
        <NewsletterForm
          id={'newsletter-form-header-menu-bottom'}
          className='text-white'
          onSuccess={showNewsletterMessage}
          inFlyout={true}
          mode='dark'
          newsletterSource='main_menu'
        />
      )
    }
  ]

  return (
    <Tab.Group onChange={() => hideNewsletterMessage()}>
      <div className={`header-menu-bottom-wrapper ${className || ''}`}>
        <Tab.List className={'flex flex-col'}>
          {tabs.map((tab, key) => (
            <Tab
              key={key}
              className={({ selected }) =>
                `mb-8 ${
                  selected ? 'fill-white' : 'fill-platinaGreen'
                } transition-colors focus:outline-none`
              }
            >
              <Icons iconName={tab.iconName} width='26px' />
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className={'ml-6 mt-2 w-full md:ml-8'}>
          {tabs.map((tab, key) => (
            <Tab.Panel key={key} className={'fill-white focus:outline-none'}>
              <div className='text-12 leading-12 tracking-3 font-Roboto mb-5 uppercase text-white'>
                {tab.panelTitle}
              </div>
              {tab.panelContent}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </div>
    </Tab.Group>
  )
}

export default HeaderMenuBottom
