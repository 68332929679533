import React, { useState, useEffect } from 'react'
import { Region } from 'msp-integrations'
import { HeaderMenuProps } from './HeaderMenu.types'
import { twMerge } from 'tailwind-merge'

const HeaderMenu = ({
  className,
  content,
  closeFlyout,
  changeFlyoutWidth
}: HeaderMenuProps) => {
  const [activeMenuOption, setActiveMenuOption] = useState(null)
  const [submenuOpacity, setSubmenuOpacity] = useState('opacity-0 h-0')

  useEffect(() => {
    let timer = null

    if (activeMenuOption?.children?.components) {
      changeFlyoutWidth('w-screen md:max-w-[700px]')
      timer = setTimeout(() => {
        setSubmenuOpacity('opacity-1 h-full')
      }, 350)
    } else {
      changeFlyoutWidth('w-screen md:max-w-[440px]')
      setSubmenuOpacity('opacity-0 h-0')
    }

    return () => timer && clearTimeout(timer)
  }, [activeMenuOption?.selectedItemId])

  const mappedComponents = content?.components.map((comp) => {
    if (
      comp.id === activeMenuOption?.selectedItemId &&
      comp?.regions?.some((r) => r?.components?.length > 0)
    ) {
      return {
        ...comp,
        className: 'selected-item'
      }
    }

    return comp
  })

  const mappedContent = {
    ...content,
    components: mappedComponents
  }

  return (
    <>
      <ul className={twMerge('header-menu', className)}>
        <Region
          region={mappedContent}
          closeFlyout={closeFlyout}
          noWrapper
          onItemMouseEnter={setActiveMenuOption}
          eventName='navigation'
        />
      </ul>
      {activeMenuOption?.children?.components && (
        <div className={twMerge('header-submenu-wrapper', submenuOpacity)}>
          <Region
            region={activeMenuOption?.children}
            closeFlyout={closeFlyout}
            noWrapper
            eventName='navigation'
          />
        </div>
      )}
    </>
  )
}

export default HeaderMenu
